.cardMedia {
  height: 200px;
}

.cardInput {
  width: 64px;
}

.cardButton {
  flex: 1;
}

.cardLink {
  flex: 1;
  padding: 0;
  a {
    color: #fff;
    padding: 6px 16px;
  }
}