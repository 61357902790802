.buttonBig {
  width: 100%;
  height: 64px;
  border-width: 2px !important;
  font-size: 24px !important;
}

.table {
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(#000000, .1);
  border-collapse: separate;
  border-spacing: 0;
}

.th {
  border-radius: 8px 8px 0 0;
  padding: 8px;
  background-color:#D6E9C7;
}

td {
  padding: 8px;
  &.label {
    text-align: right;
    width: 120px;
    font-size: 14px;
  }
}