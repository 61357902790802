.card {
  margin: auto;
  margin-bottom: 16px;
  max-width: 800px;
}

.cardMedia {
  width: 100px;
  height: 64px;
  margin-left: 16px;
}

.cardActions {
  padding: 16px !important;
}