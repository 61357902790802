.firstView {
  height: 600px;
  padding: 0 16px;
  background: top / cover no-repeat url("/img/first-view.png");
}

.firstViewDescription {
  background-color: rgba(#ffffff, .8);
}

.logo {
  height: 40px;
  margin: 0 auto 40px;
  display: block;
}

.buttonBig {
  width: 100%;
  height: 64px;
  border-width: 2px !important;
  font-size: 24px !important;
}

.noteList {
  margin: 8px;
}

h5 span {
  margin-left: 2rem !important;
  font-size: 1rem;
}
