.logoWrapper {
  flex-grow: 1;
}

.logo {
  height: 24px;
}

.cart {
  transition: all ease;
  &:hover {
  opacity: .8;
}
}