.buttonBig {
  width: 100%;
  height: 64px;
  border-width: 2px !important;
  font-size: 24px !important;
}

.inputMd {
  max-width: 320px;
  width: 100%;
}

.inputLg {
  width: 100%;
}

.noteList {
  margin: 8px;
}